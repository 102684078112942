<template>
<div>
  <div class="title_div">经营分析</div>
  <!--搜索-->
  <!-- <div style="height:5.8vh;display:flex;align-items:center;justify-content:center;background-color: #F7F7F7;">
    <div class="search_body">
      <img src="@/assets/icon/sousuo.png" style="height:3.6vw;weight:3.6vw;margin-right:2vw;">
      <input type="text" placeholder="请输入搜索报表名称" class="search_input">
    </div>
  </div> -->
  <!--主体-->
  <div>
    <div v-for="(item,index) in table_data" :key="index" class="every_option" @click="goInfo(item)">
      <div style="display:flex;">
        <img src="@/assets/tabbar/jingyingfenxi_wei.png" style="height:4vw;width:4vw;margin-left:7vw;">
        <div style="font-size:3.4vw;margin-left:6vw;">{{item.name}}</div>
      </div>
      <div>
        <img src="@/assets/icon/fanhui.png" style="height:4vw;width:4vw;margin-right:4vw;">
      </div>
    </div>
  </div>
  <!--底部tab栏-->
  <Tabbar :choose_index="0" :user_type2="user_type2" :type="pagetype"></Tabbar>

</div>
</template>

<script>
import Tabbar from '@/components/Tabbar'
export default {
  components :{
    Tabbar
  },
  data() {
      return {
        userType:'',//用户type
        shopId:'',//账号id
        table_data:[],//列表
        loading:'',//loading
        user_type2:false,//用户是否区域看板
        pagetype:0,
      };
  },
  created(){
    this.user_api();
  },
  methods:{
    //选择队列
    goInfo(item){
      if(item.type==1||item.type==2){
        //type为1 进入博爱供应商应付报表
        this.$router.push('./supplier?pagetype='+item.type);
      }else{
        localStorage.setItem("choose_item", JSON.stringify(item));
        this.$router.push('./info');
      }
    },
    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    //获取用户信息
    user_api(){
      this.showLoading();
      this.$axios.post(this.$api.user_api).then(res=>{
       
        localStorage.setItem("user_info", JSON.stringify(res.data.data));
        this.userType = res.data.data.type;
        if(this.userType==0){
          this.pagetype = 1;
        }else if(this.userType==2){
          this.user_type2 = true;
        }else{
          this.pagetype = 2;
        }
        this.shopId = res.data.data.shopId;
        this.menu_api();
      })
    },
    //获取用户当前菜单
    menu_api(){
      this.$axios.post(this.$api.menu_api,{
        userType:this.userType
      }).then(res=>{
        // console.log(res,"skjhfdoiejfdogtrg")
        this.loading.close();
        this.table_data = res.data.data;
        console.log(this.table_data,"this.table_data")
        //若是总店账号 显示博爱供应商应付报表
        if(this.shopId=='%%'){
          this.table_data.push({
            name:'博爱供应商应付报表',
            type:1,
          })
          this.table_data.push({
            name:'以销定结供应商应付报表',
            type:2,
          })
        }
        console.log(res,"67676")
      })
    }
  }
}
</script>
<style scoped>
.search_body{
  width: 90vw;
  height:4vh;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:18px;
}
.title_div{
  height:9vh;
  background-image: linear-gradient(#04A9F3, #4EC5FB);
  line-height:12vh;
  color:#FFFFFF;
  text-align: center;
  font-size:4.6vw;

}
.search_input{
  outline: none;
  border: none;
  width:30vw;
}
input::-webkit-input-placeholder { /* WebKit browsers */
  color:#E2E2E2;
  font-size:3vw;
}
.every_option{
  height:7vh;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom: 1px solid #E2E2E2;
}

</style>